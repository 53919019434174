import { lazy } from "react";
import Loadable from "app/components/Loadable";

const CrudTable = Loadable(lazy(() => import("./CrudTable")));
const CatalogTable = Loadable(lazy(() => import("./catalogs/CatalogTable")));

const crudRoutes = [
    { path: "/crud-table", element: <CrudTable /> },
    { path: "/catalogs/catalog1", element: <CatalogTable /> }
  ];

export default crudRoutes;
