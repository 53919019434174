import { authRoles } from "app/auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: "dashboard",
    auth: authRoles.sa, // ONLY SUPER ADMIN(SA) CAN ACCESS
  },


  { label: "Pages", type: "label" },
  

  {
    name: "Catalogs",
    icon: "description",
    children: [
      { name: "Jobs", path: "/catalogs/catalog1", iconText: "CL" }
    ],
  },
  

  { label: "Apps", type: "label" },
  { name: "CRUD Table", icon: "format_list_bulleted", path: "/crud-table" }
  
];
